<template>
    <a @click.prevent="openIncomingInvoice(row.item,row.item.type)"
       href="#">{{ row.item.id }}</a>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        openIncomingInvoice(incomingInvoice, type) {
            this.$root.$children[0].openModal('incoming-invoices-modal', {
                    id: incomingInvoice.id,
                    type: type,
                }, this.refreshTable,
                {}
            )
        },
    }
}
</script>